@import "../../assets/styles/base";

.report-a-bug-cta {
  padding: 10px 20px;
  z-index: 99999999;
  background-color: var(--black);
  position: fixed;
  right: 0;
  bottom: 30%;
  color: var(--white);
  transform: rotate(-90deg);
  transform-origin: bottom right;
  margin: 0;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
}

.report-bug-popup {
  .report-bug-popup-container {
    text-align: left;
    margin-top: 20px;
  }

  .report-bug-input-wrapper {
    margin-bottom: 20px;
  }
  .report-bug-input-wrapper  .dd-wrapper .dd-list-wrapper{
    padding: 0;
  }
  .report-bug-input-wrapper  .dd-wrapper .dd-list-wrapper .dd-list{
    border: 1px solid var(--grey100);
  }
  .report-bug-input-title {
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 14px;
  }
  input::placeholder{
    font-size: 16px;
  }
  .option-placeholder{
    color: var(--grey600);
  }

  .report-bug-description .textarea {
    padding: 10px !important;
  }

  .popup--buttonGroup {
    justify-content: flex-start !important;

    .report-bug-submit {
      align-self: flex-start;
      width: 180px;
      span {
        padding: 15px 40px !important;
      }
    }
  }
}
.common-popup.popup .popover-content {
  background: #ffffff !important;
  @include tablet_media_query{
    min-width: 400px;
    width: 94%;
  }
}
@include mobile_media_query {
  .popup--btn {
    width: 100%;
  }

  .report-bug-popup-container .dd-header {
    margin-top: 0px !important;
  }
}

.hidden {
  display: none;
}

.report-bug-popup .popover-content {
  @include mobile_and_tablet_sm_media_query{
    width: calc(100vw - 8px) !important;
  }
}
@include mobile_and_tablet_sm_media_query{
  .report-bug-popup .popover-content .charCount{
    font-size: 16px;
  }
  .report-bug-popup .option-placeholder,
  .report-bug-popup .report-bug-input-title,
  .report-bug-popup .native-textarea,
  .report-bug-popup .charCount{
    font-size: 16px;
  }
}