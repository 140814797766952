.msg-container {
  display: flex;
  gap: 12px;
  grid-gap: 12px;
  &:first-child {
    .msg-content_wrapper {
      .chat_prompt {
        font-weight: 500 !important;
      }
    }
  }
  .msg-avatar {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 6px;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .msg-image {
      width: inherit;
      border-radius: inherit;
    }
  }
  .msg-content_wrapper {
    .company_name {
      color: #9d9ea3;
      font-size: 10px;
      font-weight: 600px;
      text-transform: uppercase;
      padding: 6px 0;
    }
    .chat_prompt {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #000;
      padding-bottom: 24px;
      p {
        display: inline;
      }
    }
    .chat_options {
      margin-top: 4px;
      width: 250px;
      .option_select_radio {
        display: flex;
        gap: 32px;
        grid-gap: 32px;
        margin-top: 16px;
        .custom-radio {
          width: 200px;
        }
      }
    }
  }
}

.fist-prompt {
  font-weight: 500 !important;
}
