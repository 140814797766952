.login-options {
  border: 1px solid var(--grey100);
  border-radius: 3px;
  padding: 11px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #fff;
  cursor: pointer;
  height: 48px;
  .lo-text {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }
  img {
    display: flex;
    align-self: center;
    height: 100%;
  }
  &:last-child {
    margin-bottom: 0;
  }
} /* login-options */
