/*
-----------------------------

Mobile  =   0    -  500

Tablet:   
  Small =   501  -  800 
  Large =   801  -  1050 

Desktop =   1051 -  1920 

-------------------------------
max-width = less than or equal to
min-width = greater than or equal to
-------------------------------
*/

// variables
$mobile_min_width: 392px;
$mobile_max_width: 500px;
$tablet_small_max_width: 800px;
$tablet_large_max_width: 1050px;
$desktop_medium_max_width: 1400px;
$desktop_large_max_width: 1920px;

@mixin mobile_sm_media_query {
  @media (max-width: $mobile_min_width) {
    @content;
  }
}

@mixin mobile_media_query {
  @media (max-width: $mobile_max_width) {
    @content;
  }
}

@mixin mobile_media_query_with_min_width {
  @media (max-width: $mobile_max_width) and (min-width: $mobile_min_width) {
    @content;
  }
}

@mixin tablet_media_query {
  @media (min-width: #{$mobile_max_width + 1}) and (max-width: $tablet_large_max_width) {
    @content;
  }
}

@mixin mobile_and_tablet_media_query {
  @media (max-width: #{$tablet_large_max_width}) {
    @content;
  }
}

@mixin tablet_sm_media_query {
  @media (min-width: #{$mobile_max_width + 1}) and (max-width: $tablet_small_max_width) {
    @content;
  }
}

@mixin tablet_lg_media_query {
  @media (min-width: #{$tablet_small_max_width + 1}) and (max-width: #{$tablet_large_max_width + 1}) {
    @content;
  }
}

@mixin tablet_sm_and_tablet_lg_media_query {
  @media (min-width: #{$tablet_small_max_width + 1}) and (max-width: $tablet_large_max_width) {
    @content;
  }
}
@mixin desktop_md_media_query {
  @media (min-width: #{$tablet_large_max_width + 1}) and (max-width: #{$desktop_medium_max_width - 1}) {
    @content;
  }
}

@mixin ipadpro_max_width_media_query {
  @media (max-width: #{$tablet_large_max_width + 1}) {
    @content;
  }
}
@mixin desktop_media_query {
  @media (min-width: #{$tablet_large_max_width + 1}) {
    @content;
  }
}

@mixin mobile_and_tablet_sm_media_query {
  @media (max-width: #{$tablet_small_max_width}) {
    @content;
  }
}
@mixin desktop_md_media_query {
  @media (min-width: #{$tablet_small_max_width}) {
    @content;
  }
}

@mixin tablet_and_desktop_media_query {
  @media (min-width: #{$mobile_max_width + 1}) {
    @content;
  }
}

@mixin desktop_large_media_query {
  @media (min-width: #{$desktop_large_max_width + 1}) {
    @content;
  }
}
// 990px to 1200px
@mixin ionic_SizeMd {
  @media (min-width: #{$tablet_small_max_width + 190}) and (max-width: #{$desktop_medium_max_width - 199}) {
    @content;
  }
}
// 1200px above
@mixin desktopLg {
  @media (min-width: #{$desktop_medium_max_width - 199}) {
    @content;
  }
}

// below 1200px
@mixin belowDesktopLg {
  @media (max-width: #{$desktop_medium_max_width - 199}) {
    @content;
  }
}

@mixin desktopXLg {
  @media (min-width: #{$desktop_medium_max_width + 200}) {
    @content;
  }
}
