@import "@assets/styles/breakpoints.scss";

/* ============== LOGIN =============== */
.login-page {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
  background: #e5e5e5;
  @include mobile-media-query {
    background: #fff;
  }
}
.login-wrapper {
  width: 500px;
  padding: 40px;
  display: block;
  background-color: var(--white);
  border-radius: 12px;
  .login-header {
    margin-bottom: 24px;
    align-items: center;
    .lgh-avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: var(--grey100);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
      flex: 0 0 auto;
    } /*lgh-avatar */
    .lgh-title {
      font-size: 24px;
      font-weight: 600;
    } /*lgh-title */
    .lgh-signUp {
      display: none;
      font-size: 14px;
      font-weight: 400;
      color: var(--grey600);
      margin-top: 8px;
      a {
        color: var(--primary);
      }
    } /*lgh-signUp */
  } /*login-header */

  .lgn-input_block {
    width: 100%;
    margin-bottom: 16px;
    .input-wraper {
      .input-block {
        width: 100%;
        border: 1px solid var(--grey300);
        .Input {
          // padding-left: 10px !important;
          // padding-right: 10px !important;
          input {
            --padding-start: 10px;
          }
        }
      }
    }
  } /*lgn-input_block */
  .lgn-submit_btn {
    margin-top: 24px;
    margin-bottom: 24px;
    ion-button {
      width: 100%;
      height: 48px;
    }
  } /*lgn-submit_btn*/
  .lgn-or_divider {
    h4 {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid var(--grey200);
      line-height: 0.1em;
      margin: 10px 0 36px;
      span {
        font-size: 14px;
        font-weight: 400;
        color: var(--grey400);
        background: var(--white);
        padding: 0 10px;
      }
    }
  } /*lgn-or_divider*/
  .input_error {
    color: var(--danger);
    font-size: 12px;
    margin-top: 8px;
  }
  @include mobile-media-query {
    background-color: unset;
    border-radius: unset;
  }
} /*login-wrapper */
