@import "@assets/styles/breakpoints.scss";

.chatbot-prompt-suggestion {
  margin: 0px 104px;
  .suggestion-wrapper {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    .suggestion-box {
      cursor: pointer;
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: white;
      max-width: none;
      box-sizing: border-box;
      border-radius: 12px;
      font-size: 14px;
      height: 40px;
      line-height: 20px;
      margin-bottom: 8px;
      margin-left: 0;
      margin-right: 12px;
      padding: 0 12px;
      text-align: left;
      .ellipsis-container {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.action-button{
        background-color: rgba(0, 0, 0, 0.422);
        @include mobile-media-query {
          width: 100%;
        }
      }

      @include mobile-media-query {
        width: 100%;
        text-overflow: ellipsis;
      }
    }
  }

  @include mobile-media-query {
    width: 100%;
    margin: 0px;
    padding: 0px 12px;
  }
}