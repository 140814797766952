@import "./variables";
@import "./colors";

:root,
a,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea,
::placeholder {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: var(--grey900);
}

$box-shadow-primary: var(--box-shadow-primary);
* {
  box-sizing: border-box;
  padding: 0;
  font-family: "Inter", sans-serif;
  // font-weight: 400;
  // font-size: 16px;
}

html.md {
  --ion-default-font: "Inter", sans-serif;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.no_float {
  float: none !important;
}

.left_col {
  padding-right: 8px;
  &.left_col_full {
    @media (max-width: 500px) {
      padding-right: 0;
    }
  }
  @media (max-width: 500px) {
    padding-right: 0;
  }
}
.right_col {
  padding-left: 8px;
  &.right_col_full {
    @media (max-width: 500px) {
      padding-left: 0;
    }
  }
  @media (max-width: 1000px) {
    padding-left: 0;
  }
}

html.md {
  --ion-default-font: "Inter", sans-serif;
}

.no_border {
  border: 0 !important;
}

@media (min-width: 1025px) {
  .d-lg {
    display: block;
  }
  .d-md {
    display: block;
  }
  .d-md-none {
    display: block !important;
  }
  .d-md-only {
    display: none;
  }
  .d-sm {
    display: none;
  }
  // display flex;
  .d-f-lg {
    display: flex;
  }
  .d-f-md {
    display: none;
  }
  .d-f-sm {
    display: none;
  }
}

@media (max-width: 1024px) {
  .d-lg {
    display: none;
  }
  .d-md {
    display: block;
  }
  .d-md-none {
    display: block !important;
  }
  .d-md-only {
    display: block;
  }
  .d-sm {
    display: none;
  }
  // display flex;
  .d-f-lg {
    display: flex;
  }
  .d-f-md {
    display: flex;
  }
  .d-f-sm {
    display: none;
  }
}
@media (max-width: 500px) {
  .d-lg {
    display: none;
  }
  .d-md {
    display: none;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-only {
    display: none;
  }
  .d-sm {
    display: block;
  }
  // display flex;
  .d-f-lg {
    display: none;
  }
  .d-f-md {
    display: none;
  }
  .d-f-sm {
    display: flex;
  }
}

/* HIDE SCROLLBAR BUT KEEP FUNCTIONALITY */
.hide-scrollbar {
  overflow-x: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.asterisk::after {
  content: "*";
  margin-left: 2px;
}

.error_border {
  @include tablet_and_desktop_media_query {
    border: 1px solid var(--danger) !important;
  }

  @include mobile_media_query {
    border: 1px solid var(--danger) !important;
    padding-left: 8px !important;
    margin-bottom: 4px !important;
    border-radius: 16px !important;
  }
}

body {
  &:has(.messages-v2) {
    @include mobile_media_query {
      overflow: hidden;
    }
  }
}
