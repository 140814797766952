.msg-container {
  .msg-avatar_reply {
    background: #f3f3f7;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 6px;
    font-weight: 400;
    color: #898a8f;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
