.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}
.dd-wrapper {
  position: relative;

  .dd-header {
    height: 48px;
    border: 1px solid var(--grey200);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;
    position: relative;
    background: #fff;

    &:focus {
      border: 1px solid var(--grey900);
    }

    .disabled {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      background-color: var(--grey50);
      z-index: 2;
      display: none;
    }

    .dd-header-title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: initial;
    }

    .dd-header-icon {
      transform: rotate(180deg);
    }

    .dd-header-icon-rotated ion-img {
      z-index: 3;
    }
  }

  .dd-list-wrapper {
    height: auto;
    width: 100%;
    position: absolute;
    padding-inline-end: 5px;
    padding-right: 5px;
    margin-top: 8px;
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: none;
    z-index: 99;
    top: 50px;

    .dd-list {
      padding: 7px 11px;
      overflow-y: scroll;
      background-color: #fff;
      border-radius: 8px;

      .dd-list-item {
        display: flex;
        align-items: center;
        padding: 8px 16px 8px 8px;
        border-radius: 4px;
        scroll-margin: 50px 0 0 50px;
        cursor: pointer;

        &:hover {
          background-color: var(--grey50);
        }
      }

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--grey300);
        border-radius: 5px;
        width: 3px;
        height: 10px;
        background-clip: content-box;
      }

      &::-webkit-scrollbar-track {
        margin: 11px 0;
        width: 6px;
      }
    }
  }
}

.disableClass {
  background-color: var(--grey50) !important;
}

@media (max-width: 500px) {
  .dd-wrapper {
    .dd-header {
      margin-top: 16px;
      gap: 8px;
    }
  }
}
