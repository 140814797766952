* {
  font-family: "Poppins" !important;
}
body {
  font-family: "Poppins" !important;
}
.chatbot_container {
  width: 100dvw;
  width: 100vw;
  height: 100dvh;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #d8dae0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //   Preloader
  .chatbot-preloader {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // Stop generating button
  .stop-streaming-btn {
    display: flex;
    width: 150px;
    align-self: center;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 5px;
    border: 1px solid #b9b9bb;
    background-color: #fcfcff;
    font-size: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
    .stop-streaming-btn-icon {
      display: flex;
      justify-items: center;
      align-items: center;
      padding: 5px;
    }
  }
  //   Header section -------------
  .chatbot-header {
    height: auto;
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 500px) {
      background: rgba(249, 249, 249, 0.94);
      border-bottom: 0.5px solid #3c3c433b;
      text-align: center;
    }
    &.border_bottom {
      border-bottom: 1px solid #d8dae0;
    }
    .header-col {
      &.header_title {
        font-size: 24px;
        font-weight: 600;
        @media (max-width: 500px) {
          font-size: 16px;
        }
      }
      .header-language {
        color: #0f5cf0;
        cursor: pointer;
        border-radius: 12px;
      }
      .header-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
      }
    }
  }
  .header-powered_text {
    @media (max-width: 500px) {
      display: block;
      height: 32px;
      padding: 8px 16px;
      font-size: 10px;
      font-weight: 300;
      line-height: 15px;
      color: #5c5c5e;
    }
  }
  //content section ---------------------
  .chatbot-content {
    padding: 24px;
    height: 70dvh;
    height: calc(100vh - 230px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    grid-gap: 32px;
  }
  //Footer section ---------------------
  .chatbot-footer {
    padding: 24px 104px 8px 104px;
    background: #fcfcff;
    @media (max-width: 500px) {
      padding: 24px 24px;
    }
    .footer-input_container {
      padding: 8px 8px 8px 16px;
      min-height: 60px;
      background: #fff;
      border: 1px solid #b9b9bb;
      border-radius: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      grid-gap: 16px;
      position: relative;

      width: 100%;
      @media (max-width: 500px) {
        background: unset;
        border: unset;
        padding: 0;
      }
      .footer-chat_input {
        width: 92%;
        border: 0;
        height: 25px;
        resize: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        &::placeholder {
          color: #8a8a8e;
          font-size: 16px;
        }
        @media (max-width: 500px) {
          padding: 12px;
          width: 80%;
          height: 47px;
          background: #fff;
          border: 1px solid #b9b9bb;
          border-radius: 10px;
          overflow-y: auto;
          line-height: 22px;
        }
      }
      .send_btn {
        background: #09090e80;
        border-radius: 50%;
        border: none;
        height: 44px;
        width: 44px;
        @media (max-width: 500px) {
          border-radius: 8px;
          width: 43px;
          height: 43px;
          img {
            margin-left: -2px !important;
            margin-top: 4px !important;
          }
        }
        &.active {
          background: #000;
        }
        &:hover {
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
        }
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-left: -3px;
          margin-top: 3px;
        }
      }
      .stop_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border: none;
      }
      .delete_btn {
        position: absolute;
        right: -60px;
        width: 32px;
        height: 32px;
        background: #fff;
        border-radius: 50%;
        border: none;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
        }
      }
    }
    .footer-powered_text {
      margin-top: 8px;
      font-size: 10px;
      font-weight: 300;
      line-height: 15px;
      text-align: center;
      color: #5c5c5e;
    }
  }
}

.close-button-mobile {
  color: red;
}

.close-button-items {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.msg_loading {
  img {
    animation: rotation 2s infinite linear;
  }
}

.modify-margin-top {
  margin-top: -70px;
}
