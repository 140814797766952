@import "./assets/styles/base";

.app {
  &-logo {
    height: 40vmin;
    pointer-events: none;
    @media (prefers-reduced-motion: no-preference) {
      animation: app-logo-float infinite 3s ease-in-out;
    }
  }

  &-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }

  &-link {
    color: #704cb6;
  }
}

.error-content-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  max-width: 70%;
}

.pos_absolute {
  position: absolute !important;
}
// Removing + and - from number input

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
*:focus {
  outline: none;
}
*::-webkit-scrollbar {
  width: 3px;
  background: none !important;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--grey700);
  outline: 1px solid var(--grey700);
  border-radius: 10px;
}

@keyframes app-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

[popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.hubspot-page-iframe {
  width: 100%;
  height: 100vh;
  border: none;
}

@media screen and (min-width: 800px) {
  .hubspot-page-iframe {
    padding-left: 45px;
  }
}

.report-a-bug-cta {
  padding: 10px 20px;
  z-index: 99999999;
  background-color: var(--black);
  position: fixed;
  right: 0;
  bottom: 30%;
  color: var(--white);
  transform: rotate(-90deg);
  transform-origin: bottom right;
  margin: 0;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
}

.report-bug-popup {
  .report-bug-popup-container {
    text-align: left;
    margin-top: 20px;
  }

  .report-bug-input-wrapper {
    margin-bottom: 20px;
  }

  .report-bug-input-title {
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 14px;
  }

  .report-bug-description .textarea {
    padding: 10px !important;
  }

  .popup--buttonGroup {
    justify-content: flex-start !important;

    .report-bug-submit {
      align-self: flex-start;
      span {
        padding: 15px 40px !important;
      }
    }
  }
}

@include mobile_media_query {
  .popup--btn {
    width: 100%;
  }

  .report-bug-popup-container .dd-header {
    margin-top: 0px !important;
  }
}
