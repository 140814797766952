@import "@assets/styles/breakpoints.scss";

.common-popup.popup {
  --width: 600px; /* properties with -- prefix are custom css properties present in Ionic */
  text-align: center;
  --backdrop-opacity: 0;
  --box-shadow: 0;
  margin: 0;
  --backdrop-opacity: 0.6 !important;
  --background: #080d13;

  .popover-content {
    border-radius: 12px;
    min-height: fit-content;
    overflow: hidden;
    box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.15);
    .popup--content.vScroll {
      overflow-y: auto;
    }
  }
  .popup--grid {
    padding: 32px;
    --overflow: hidden;
  }

  .popup--heading-container {
    position: relative;
  }

  .popup--content {
    --overflow: hidden;
  }

  .popup--closeDiv {
    margin: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .popup--closeIconCol {
    --padding: 0;
    margin-bottom: 21px;
  }

  .popup--closeIcon {
    color: #afb0b6;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 !important;
    font-size: 20px;
    cursor: pointer;
  }

  .popup--title ion-text {
    font-family: Inter;
    color: var(--grey900);
    font-size: 30px;
    font-weight: 600;
  }
  .popup--subTitle {
    margin-bottom: 24px;
  }
  .popup--subTitle p {
    margin-bottom: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px !important;
    color: var(--grey700) !important;
  }

  .popup--caption-container {
    max-height: 470px;
    overflow-y: auto;
    overflow-x: hidden;
    @media (min-width: 768px) and (max-width: 1400px) {
      max-height: 400px;
      --max-height: 400px;
    }
  }

  .popup--buttonGroup {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    gap: 16px;
    padding-top: 10px;
  }

  .popup--btn {
    height: 45px;
  }

  .popup--btn.whiteBtn {
    background-color: white;
    color: black;
  }

  .popup--btn.blackBtn {
    background-color: black;
    color: white;
  }

  .popup_loader {
    height: 250px;
    width: 599px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 428px) and (max-width: 1024px) {
  .common-popup.popup .popup--grid {
    padding: 32px;

    .popup--closeDiv {
      margin-bottom: 16px;
    }
  }
}

.no-scroll-popup {
  --overflow: hidden;
}

.extend-popup {
  .popup--buttonGroup {
    justify-content: center !important;
  }
}

.extend-popup {
  .popup--caption-container {
    height: auto !important;
  }
  .popup--heading-container {
    display: block;
    height: auto !important;
    margin-bottom: 16px;
    .popup--title ion-text {
      font-size: 36px !important;
      display: flex;
    }
  }
  .popup--buttonGroup {
    justify-content: center !important;
  }
  .popup--btn {
    min-width: 120px;
  }
  @include mobile_media_query {
    .popover-content {
      border-radius: 12px !important;
      .popup--title ion-text {
        font-size: 24px !important;
        display: flex;
      }

      .popup--btn {
        height: 40px !important;
      }
    }
  }
}

.cancel-popup {
  .popup--caption-container {
    height: auto !important;
  }
  .popup--heading-container {
    display: block;
    height: auto !important;
    margin-bottom: 16px;
    .popup--title ion-text {
      font-size: 36px !important;
    }
  }
  .popup--buttonGroup {
    justify-content: center !important;
  }
  .popup--btn {
    min-width: 120px;
  }
  @include mobile_media_query {
    .popover-content {
      border-radius: 12px !important;
      .popup--title ion-text {
        font-size: 24px !important;
      }

      .popup--btn {
        height: 40px !important;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .common-popup.popup .popover-content {
    max-width: 100vw !important;
    left: 0 !important;
    margin: 0 24px;
    border-radius: 12px 12px 0 0;

    .popup--grid {
      padding: 32px;
    }

    .popup--closeDiv {
      margin-bottom: 8px;
    }

    .popup--title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }

    .popup--subTitle {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
  .mobile_popup_bottom.common-popup.popup {
    .popover-content {
      margin: 0;
      width: 100% !important;
      top: unset !important;
      bottom: 0 !important;
    }
  }
}

@media only screen and (max-width: 360px) {
  .common-button.button.whiteBtn,
  .common-button.button.blackBtn {
    width: 100% !important;
    margin-bottom: 24px !important;
  }

  .common-button.button.blackBtn {
    margin-bottom: 0px !important;
  }
}
