.error-wrapper {

  border-radius: 12px;
  padding: 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
  .redirect-btn {
    font-family: "Nunito", sans-serif;
    display: inline-block;
    font-weight: 700;
    border-radius: 40px;
    text-decoration: none;
    color: var(--primary);
    cursor: pointer;
  }

  .error-details {
    color: var(--danger);
    max-height: 40vh;
    overflow: hidden;
    overflow-y: scroll;
    border-radius: 12px;
    background: #ffffff;
    margin-top: 24px;
    padding: 24px;
    box-shadow: #d1d1d140 0px 30px 60px -12px inset, #8888884d 0px 18px 36px -18px inset;
  }
}
