@import "@assets/styles/breakpoints.scss";

.navbar_expansion_component {
  user-select: none;
  flex: inherit;
  display: flex;
  position: sticky;
  height: 100vh;
  background: #000;
  float: left;
  bottom: 0;
  top: 0;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  z-index: 99999;
  box-shadow: 4px 0px 6px 0px rgba(0, 0, 0, 0.2);
  margin: 0;
  .nbwc-logo {
    margin-bottom: 50px;
    padding: 24px 24px 0 24px;
  }
  img {
    width: 18px;
    height: 18px;
  }
  &.expand {
    .nbwc-lower_menu {
      padding: 24px;
    }
  }
  &.shrink {
    .nbwc-lower_menu {
      padding: 0 0 24px 0;
    }
  }
  .nbwc-upper_menu .nbwc-menu_item,
  .nbwc-lower_menu .nbwc-menu_item,
  .nbwc-expand-menu .nbwc-menu_item {
    height: 34px;
    cursor: pointer;
    display: inline-table;
    .menu_item_tooltip {
      display: none;
    }
    .submenu-tooltip {
      display: none;
    }
  }
  .nbwc-lower_menu {
    display: inline;
  }
  &.expand {
    width: 220px;
    max-width: 279px;
    position: absolute;
    overflow: hidden;
    &.animate {
      animation: expansion 0.8s ease-in forwards;
      width: 73px;

      .nbwc-upper_menu .nbwc-menu_item,
      .nbwc-lower_menu .nbwc-menu_item {
        animation: textAnimation 0.6s ease-in forwards;
      }

      .nbwc-expand-logo {
        .span-text {
          animation: textAnimation 0.6s ease-in forwards;
        }
      }
    }
    .nbwc-upper_menu,
    .nbwc-lower_menu {
      display: inline;
    }
    .nbwc-logo img {
      width: 172px;
      height: auto;
    }

    .nbwc-upper_menu .nbwc-menu_item,
    .nbwc-lower_menu .nbwc-menu_item,
    .nbwc-expand-menu .nbwc-menu_item {
      color: var(--grey500);
      font-size: 14px;
      width: 100%;
      margin-left: 0;
      justify-content: left;
      cursor: pointer;
      img {
        margin-right: 25px;
        float: left;
      }

      &.current {
        color: var(--white);
        height: 34px;
        font-weight: 500;
        background: var(--grey800);
        &.item-collapsible {
          color: var(--grey500);
        }
        .menu_items {
          background: var(--grey800);
          .msgIcon {
            img {
              filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
                hue-rotate(305deg) brightness(111%) contrast(101%);
            }
          }
          .item--name {
            color: var(--white);
          }
        }
      }
      &.open {
        background: var(--grey850);
      }
      .current--marker {
        width: 5px;
        background: var(--white);
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      .item--name {
        position: relative;
        animation: textOpacityAnimation 0.5s ease-in forwards;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
      }
      .menu_items {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 24px;
        &.disabled {
          pointer-events: none;
        }
      }
      &.item-collapsible {
        align-items: center;
        justify-content: space-between;
        .down-fill-arrow {
          width: 8px;
          opacity: 0.5;
          rotate: 0deg;
          &.open {
            rotate: 180deg;
            opacity: 1;
          }
        }

        .subMenu_items {
          display: block;
          height: fit-content;
          width: 100%;
          .item-dropdown {
            ul {
              list-style: none;
              .current {
                color: var(--white);
                font-weight: 500;
              }
              li {
                padding-left: 62px;
                ion-text {
                  display: block;
                  padding-top: 8px;
                  padding-bottom: 8px;
                  position: relative;
                  &.coming_soon {
                    &::after {
                      content: "COMING SOON";
                      position: absolute;
                      top: 1px;
                      right: 59px;
                      color: #898a8f;
                      font-size: 8px;
                      font-weight: 400;
                      display: block;
                    }
                  }
                  &.hover:hover {
                    color: #fff;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }

      .msgText {
        position: relative;
        &:hover {
          color: #fff;
          font-weight: 600;
        }

        &.coming_soon {
          &::after {
            content: "COMING SOON";
            position: absolute;
            top: -3px;
            right: -70px;
            color: #898a8f;
            font-size: 8px;
            font-weight: 400;
            display: block;
          }
        }
        .msgCounter {
          font-size: 10px;
          font-weight: 600;
          width: 12px;
          height: 12px;
          background: var(--danger);
          border-radius: 50%;
          color: var(--white);
          position: absolute;
          right: -14px;
          top: -3px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .msgIcCounter {
        font-size: 7px;
        font-weight: 600;
        width: 5px;
        height: 5px;
        background: var(--danger);
        border-radius: 50%;
        color: var(--white);
        position: absolute;
        right: 110px;
        top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .profile_img {
        width: 24px;
        height: 24px;
        float: left;
        margin-right: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--white);
        border-radius: 50%;
        overflow: hidden;
        img {
          margin-right: 0;
          width: 100%;
          height: 100%;
        }
        .profile_initial {
          font-size: 12px;
        }
      }
    }
    .nbwc-expand-logo {
      display: flex;
      align-items: center;
      justify-content: left;
      cursor: pointer;
      position: absolute;
      bottom: 30px;
      font-size: 12px;

      img {
        color: var(--white);
        height: 12px;
        width: 18px;
        margin-right: 20px;
      }
    }
    .nbwc-expand-menu {
      img {
        margin-right: 20px;
      }
      ion-text {
        color: var(--white);
      }
    }
    .show_less_more {
      display: flex;
      justify-content: right !important;
      img {
        margin-right: 0 !important;
      }
    }
    .nbwc-lower_menu {
      .nbwc-menu_item {
        display: flex;
        align-items: center;
      }
    }
  }

  &.shrink {
    padding-top: 24px;
    width: 45px;
    position: absolute;
    .nbwc-logo {
      padding: 0;
    }
    .current {
      background: var(--grey850);
      .menu_items {
        background: var(--grey800);
        .msgIcon {
          img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
              hue-rotate(305deg) brightness(111%) contrast(101%);
          }
        }
      }
    }
    &.open {
      background: var(--grey850);
    }

    &.animate {
      width: 280px;
      animation: shrinkAnimation 0.5s ease-in forwards;
    }
    .nbwc-logo {
      .nbwc-logo_wrapper {
        display: flex;
        justify-content: center;
      }
    }
    .nbwc-upper_menu {
      display: block;
    }

    .nbwc-upper_menu .nbwc-menu_item,
    .nbwc-lower_menu .nbwc-menu_item,
    .nbwc-expand-menu .nbwc-menu_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 0;
      &:hover {
        background: var(--grey850);
      }
      .item--name {
        display: none;
      }
      .item-dropdown {
        position: absolute;
        top: 0;
        left: 45px;
        background-color: var(--black);
        width: 160px;
        border-radius: 2px;
        padding-top: 8px;
        padding-bottom: 8px;
        ul {
          list-style: none;
          margin: 0;
          li {
            &.current {
              background-color: var(--grey850);
              ion-text {
                color: #fff;
                font-weight: 600;
              }
            }
            ion-text {
              display: block;
              color: var(--grey500);
              padding: 8px 24px;
              font-size: 14px;
              &:hover {
                color: #fff;
                font-weight: 600;
              }
            }
          }
        }
        .current--marker {
          display: none;
        }
      }
      .current--marker {
        width: 4px;
        background: var(--white);
        height: 34px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .nbwc-menu_item:hover {
      .current--marker {
        left: -13px;
        top: -6px;
      }
      .menu_items {
        position: relative;
        .menu_item_tooltip {
          display: block;
          color: var(--white);
          position: absolute;
          top: -7px;
          left: 24px;
          background: var(--grey850);
          width: fit-content;
          padding: 8px;
          font-size: 14px;
          display: flex;
          border-radius: 4px;
          &.coming-soon {
            top: -7px;
            right: -115px;
            color: var(--grey600);
          }
          .coming_soon_text {
            font-size: 6px;
            padding-left: 6px;
            width: 50px;
          }
        }
        .msgIcon {
          img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
              hue-rotate(305deg) brightness(111%) contrast(101%);
          }
        }
      }
      .submenu-tooltip {
        display: block;
        background: var(--black);
        color: var(--grey600);
        position: absolute;
        padding: 10px 0px;
        top: -7px;
        right: -135px;
        width: fit-content;
        font-size: 14px;
        padding-right: 20px;
        .submenu-parent-item {
          color: var(--white);
          padding: 8px 18px;
        }
        .submenu-child-item {
          position: relative;
          padding: 8px 18px;
          &.coming-soon {
            &::after {
              content: "COMING SOON";
              position: absolute;
              top: 2px;
              right: -10px;
              color: #898a8f;
              font-size: 8px;
              font-weight: 400;
              display: block;
            }
          }
        }
        .submenu-child-item {
          &.hover:hover {
            color: var(--white);
            background-color: var(--grey850);
          }
        }
      }
    }
    .msgIcon {
      position: relative;
      .msgIcCounter {
        font-size: 7px;
        font-weight: 600;
        width: 5px;
        height: 5px;
        background: var(--danger);
        border-radius: 50%;
        color: var(--white);
        position: absolute;
        right: -1px;
        top: -1px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .nbwc-expand-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      bottom: 30px;
      color: var(--white);
      font-size: 12px;
      width: 100%;

      img {
        color: var(--white);
        height: 12px;
        width: 18px;
      }
    }
    .nbwc-expand-menu {
      img {
        transform: rotate(180deg);
      }
      ion-col {
        text-align: center;
      }
    }
    .profile_img {
      width: 24px;
      height: 24px;
      min-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid white;
      border-radius: 50%;
      overflow: hidden;
      img {
        margin-right: 0;
        width: 100%;
        height: 100%;
      }
      .profile_initial {
        font-size: 12px;
        color: var(--grey500);
      }
    }
  }

  .nbwc-lower_menu {
    width: 100%;
    margin-top: auto;
    border-bottom: 0px;
    .help_menu {
      border-bottom: 1px solid var(--white);
      padding-bottom: 16px !important;
      margin-bottom: 16px;
      margin-top: 10px;
      .item--name {
        .coming_soon::after {
          top: -12px !important;
        }
      }
    }

    @include mobile_media_query {
      margin-bottom: calc(100% - 70%);
    }
  }
  .nbwc-expand-menu {
    cursor: pointer;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .logo-pointer {
    cursor: pointer;
  }
  &.mobile_navbar {
    display: none;
  }
}

.black_curtain {
  background-color: #000000;
  position: absolute;
  left: 0;
  right: 0;
  top: 48px;
  bottom: 0;
  z-index: 1;
  &.show_curtain {
    transition: all 0.3s;
    opacity: 0.5;
  }
  &.hide_curtain {
    transition: all 0.3s;
    opacity: 0;
    left: -100%;
    right: unset;
  }
}

.mobileNav {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  background-color: black;
  user-select: none;
  .nbwc-logo_wrapper {
    display: flex;
    align-items: center;
    .pl_icon {
      width: 140px;
    }
  }
  .burger_icon {
    width: 22px;
    text-align: right;
  }
}
.mobileSlideOutMenu {
  background-color: #000;
  padding: 24px 0;
  position: absolute;
  top: 48px;
  right: -301px;
  z-index: 9999;
  opacity: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  &.slideIn {
    right: 0;
    opacity: 1;
    width: 300px;
    transition: all 0.75s ease;
  }
  &.slideOut {
    right: -301px;
    opacity: 0;
    width: 0;
    transition: all 0.75s ease;
  }
  .nbwc-upper_menu {
    display: block;
    .nbwc-menu_item {
      height: 34px;
      cursor: pointer;
      display: inline-table;
      color: var(--grey500);
      font-size: 14px;
      width: 100%;
      margin-left: 0;
      justify-content: left;
      .current--marker {
        width: 5px;
        background: var(--white);
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      .menu_items {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 24px;
        .menu_item_tooltip {
          display: none;
        }
        .submenu-tooltip {
          display: none;
        }
        img {
          margin-right: 20px;
        }
        .msgIcon {
          position: relative;
          .msgIcCounter {
            font-size: 7px;
            font-weight: 600;
            width: 5px;
            height: 5px;
            background: var(--danger);
            border-radius: 50%;
            color: var(--white);
            position: absolute;
            right: -46px;
            top: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .item--name {
          position: relative;
          animation: textOpacityAnimation 0.5s ease-in forwards;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .down-fill-arrow {
            width: 8px;
            opacity: 0.5;
            rotate: 0deg;
            &.open {
              rotate: 180deg;
              opacity: 1;
            }
          }
        }
        &.disabled {
          pointer-events: none;
        }
      }
      .subMenu_items {
        display: block;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;
        color: var(--grey500);
        ul {
          list-style: none;
          padding-left: 38px;
          li {
            padding-left: 24px;
            ion-text {
              display: block;
              padding-top: 8px;
              padding-bottom: 8px;
              position: relative;
              &.coming_soon {
                &::after {
                  content: "COMING SOON";
                  position: absolute;
                  top: 1px;
                  right: 139px;
                  color: #898a8f;
                  font-size: 8px;
                  font-weight: 400;
                  display: block;
                }
              }
            }
            &.current {
              color: var(--white);
              font-weight: 500;
            }
          }
        }
      }
      .msgText {
        position: relative;
        &.coming_soon::after {
          content: "COMING SOON";
          position: absolute;
          top: -3px;
          right: -70px;
          color: #898a8f;
          font-size: 8px;
          font-weight: 400;
          display: block;
        }
      }
      &.open {
        background-color: var(--grey850);
      }
      &.current {
        color: var(--white);
        font-weight: 500;
        .menu_items {
          background: var(--grey800);
          .msgIcon {
            img {
              filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
                hue-rotate(305deg) brightness(111%) contrast(101%);
            }
          }
        }
      }
    }
  }
  .nbwc-lower_menu {
    display: grid;
    padding: 40px 24px 0;
    width: 100%;
    background: #000;
    margin-top: auto;

    .help_menu {
      border-bottom: 1px solid var(--white);
      padding-bottom: 16px !important;
      margin-bottom: 16px;
      margin-top: 10px;
    }
    .nbwc-menu_item {
      color: var(--grey500);
      font-size: 14px;
      width: 100%;
      margin-left: 0;
      justify-content: left;
      height: 34px;
      cursor: pointer;
      display: flex;
      align-items: center;

      .profile_img {
        width: 24px;
        height: 24px;
        float: left;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid white;
        border-radius: 50%;
        overflow: hidden;
        img {
          margin-right: 0;
          width: 100%;
          height: 100%;
        }
        .profile_initial {
          font-size: 12px;
        }
      }
      .item--name {
        position: relative;
        animation: textOpacityAnimation 0.5s ease-in forwards;
        display: flex;
        justify-content: space-between;
        align-items: center;
        float: left;
        .msgText {
          &.coming_soon {
            &::after {
              content: "COMING SOON";
              position: absolute;
              top: -3px;
              right: -70px;
              color: #898a8f;
              font-size: 8px;
              font-weight: 400;
              display: block;
            }
          }
        }
      }
      img {
        margin-right: 20px;
        float: left;
      }
    }
    .show_less_more {
      display: flex;
      justify-content: right !important;
    }
  }
}
.d-md-sm {
  @include ipadpro_max_width_media_query {
    display: block;
  }
  @include desktop_media_query {
    display: none;
  }
}
