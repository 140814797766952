.textarea-wrapper_container{
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--grey900);

    .label{
        font-weight: 600;
        font-size: 14px;
    }

    .textarea{
        border: 1px solid var(--grey200);
        border-radius: 8px;
        background: var(--white);
        padding: 16px 24px;
    }

    ion-textarea::placeholder{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--grey400);
    }
}

.charCount {
    margin-top: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--grey600);
    display: block;
}
