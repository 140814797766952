@import "@assets/styles/breakpoints.scss";
/* ============== Buttons =============== */
.btn {
  --border-radius: 3px;
  border-radius: 3px;
  --display: flex;
  --align-items: center;
  --justify-content: center;
  --font-weight: 600;
  font-weight: 600;
  --text-decoration: none;
  --cursor: pointer;
  --box-shadow: 0px 3px 8px rgba(8, 64, 173, 0.35);
  --text-transform: capitalize;
  text-transform: capitalize;
  margin: 0;
  overflow: auto;

  &:hover {
    --border-radius: 3px;
  }

  span {
    font-size: 16px;
    letter-spacing: 0;
    font-weight: normal;
  }
}

/* ----- Primary Button ------ */
.btn.btn-primary,
.btn.btn-primary.button {
  --background: var(--primary-gradient);
  --color: var(--white);

  &:hover {
    --background: var(--primary-hover-gr);
    --border-radius: 3px;
  }
}
.btn {
  &.button-default {
    --width: 140px;
    --height: 48px;
    width: 140px;
    height: 48px;
  }

  &.button-small {
    --width: 90px;
    --height: 32px;
    width: 90px;
    height: 32px;
  }

  &.button-normal {
    --width: 130px;
    --height: 48px;
    width: 130px;
    height: 48px;

    span {
      --font-size: 16px;
    }
  }

  &.button-large {
    --width: 170px;
    --height: 56px;
    width: 170px;
    height: 56px;

    span {
      --font-size: 16px;
    }
  }
}

.btn.btn-primary.btn-active,
.btn.btn-primary.btn-active.button {
  --background: var(--primary-dark);
  --color: var(--white);
  --box-shadow: unset;
  box-shadow: unset;
}
.btn.btn-primary.btn-disabled,
.btn.btn-primary.btn-disabled.button {
  --background: var(--primary-disable-gr);
  --color: var(--white);
  --box-shadow: unset;
  box-shadow: unset;
}

/* ----- Black Button ------ */
/* ========================= */
.btn.btn-black,
.btn.btn-black.button {
  --background: var(--grey900);
  --color: var(--white);
  --box-shadow: unset;
  box-shadow: unset;
}

.btn.btn-black {
  &.btn-sm {
    --width: 90px;
    --height: 32px;
    width: 90px;
    height: 32px;
  }

  &.btn-normal {
    --width: 130px;
    --height: 48px;
    width: 130px;
    height: 48px;

    span {
      --font-size: 16px;
    }
  }

  &.btn-lg {
    --width: 170px;
    --height: 56px;
    width: 170px;
    height: 56px;

    span {
      --font-size: 16px;
    }
  }
}

.btn.btn-black.btn-disabled,
.btn.btn-black.btn-disabled.button {
  --background: var(--black-disable);
  --color: var(--white);
}
.btn.btn-black:hover {
  --background: var(--white);
  color: var(--black);
  border: 1px solid var(--grey900);

  .btn-loader {
    --color: var(--black);
  }
}

/* ----- Outline Button ------ */
.btn.btn-outline,
.btn.btn-outline.button {
  --background: unset;
  --border: 1px solid var(--grey900);
  border: 1px solid var(--grey900);
  --color: var(--grey900);
  box-shadow: none;
  --box-shadow: none;
  overflow: auto;

  &:hover {
    --background: var(--grey50);
    --border-radius: 3px;
  }

  &:focus {
    --background: var(--grey100);
    --box-shadow: unset;
    box-shadow: unset;
  }
}
.btn.btn-black-outline {
  --background: unset;
  --border: 1px solid var(--black);
  border: 1px solid var(--black);
  --color: var(--black);
}
.btn.btn-primary-outline {
  --background: unset;
  --border: 1px solid var(--primary);
  border: 1px solid var(--primary);
  --color: var(--primary);
}

.btn.btn-outline,
.btn.btn-primary-outline,
.btn.btn-black-outline {
  &.btn-sm {
    --width: 90px;
    --height: 32px;
    width: 90px;
    height: 32px;
  }

  &.btn-normal {
    --width: 130px;
    --height: 48px;
    width: 130px;
    height: 48px;
    span {
      --font-size: 16px;
    }
  }

  &.btn-lg {
    --width: 170px;
    --height: 56px;
    width: 170px;
    height: 56px;

    span {
      --font-size: 16px;
    }
  }
  &.btn-full {
    --width: 100%;
    --height: 48px;
    width: 100%;
    height: 48px;
  }
}

.btn.btn-outline.btn-disabled,
.btn.btn-outline.btn-disabled.button {
  --border: 1px solid var(--grey300);
  border: 1px solid var(--grey300);
  --color: var(--grey300);
  --box-shadow: unset;
  box-shadow: unset;
  cursor: copy;
  --cursor: copy;

  &:hover {
    --background: unset;
    background: unset;
  }
}

/* -------- +++ Icons Button +++ ------- */
.btn.btn-outline,
.btn.btn-outline.button {
  .btn-icon {
    margin-right: 8px;
    min-width: 14px;
  }
}
